<template>
    <footer>
        <p class="text-center mt-3">© {{new Date().getFullYear()}} Dashboard | Favicon z <a href="https://www.flaticon.com/authors/pixel-perfect">Flaticon.com</a></p>
    </footer>
</template>

<script>
import '../css/components.css'

export default {
    name: 'Footer'
}
</script>